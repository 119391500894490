import { PropTypes } from 'prop-types';
import LetterTile from '../Letter-tile/Letter-tile';
import './Word.css';

export default function Word(props) {
    return (<div className='word compact'>
        <div className='word-letters'>
        {[...props.text].map((c, i) => {
            return <LetterTile key={i} letter={c} index={i}/>
        })}
        </div>
    </div>);
}

Word.propTypes = {
    text: PropTypes.string.isRequired
}