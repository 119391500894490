import PropTypes from 'prop-types';
import './Letter-tile.css';

export default function LetterTile(props) {
    var className = "letter-tile letter-" + props.letter.toLowerCase();
    if (props.index == 0) {
        className += " first-letter";
    }
    return (<div className={className}>
        {props.letter}
    </div>)
}

LetterTile.propTypes = {
    letter: PropTypes.string
};