import React, { useState, useEffect } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import './App.css';
import { Navbar, Nav, Button, Form } from 'react-bootstrap';
import Word from './components/Word/Word';
import { COGNITO } from "./configs/aws";
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure({
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
});

function signOut() {
  console.log("Signing out");
  Auth.signOut().then(data => console.log(data))
      .catch(err => console.log(err));
}

const fetchNames = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const apiurl = 'https://api.arniebelle.com/names';

    const response = await fetch(apiurl, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });
    const json = await response.json();
    return {success: true, data: json};
  } catch (error) {
    console.log(error);
    return {success: false};
  }
}



const toTile = () => {
  document.querySelectorAll(".word").forEach(el => el.classList.toggle("compact"));
}

const enableScoring = () => {
  document.querySelectorAll(".score-names").forEach(el => el.classList.remove("hidden"));
  document.querySelectorAll(".word").forEach(el => el.classList.remove("compact"));
}

function App() {
  const [namesLoaded, setNamesLoaded] = useState(false);
  const [names, setNames] = useState([]);
  const [name, setName] = useState();
  const handleNavSelection = async (eventKey) => eventKey == "logout" ? await signOut() : undefined;

  const submitName = async e => {
    try {
        e.preventDefault();
        const session = Auth.currentSession();
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;
        const apiurl = 'https://api.arniebelle.com/names';
  
        const response = await fetch(apiurl, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name: name})
        });
        const data = await response.json();
        await loadNames();
    }
    catch (ex) {
        console.log(ex);
    }
  }

  useEffect(() => {
    (async () => {
      await loadNames();
    })();
  }, []);

  const loadNames = async () => {
    setNamesLoaded(false);
    let res = await fetchNames();
    if (res.success) {
      setNames(res.data);
      setNamesLoaded(true);
    }
  };

  return (
      <div>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Navbar.Collapse id="navbarScroll" onSelect={handleNavSelection}>
            <Nav className="me-auto">
              <Nav.Link onClick={signOut}>Sign Out</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="app">
          <header className="app-header">
              Baby Name Competition
          </header>
          <div className="app-body">
            <div className='score-names hidden'>
              <input className='name-input' placeholder='enter name'></input><Button variant='primary'>Score</Button>
            </div>
            <div className="add-names">
              <input className='name-input' placeholder='enter name' onChange={e => setName(e.target.value)}></input><Button variant='primary' onClick={submitName}>Add Name</Button>
                {/* <Form onSubmit={submitName}>
                    <Form.Group className="mb-3" controlId="formName">
                    <Form.Label>Add a name choice</Form.Label>
                    <div className='form'>
                    <Form.Control className="name-selection" placeholder="Enter name" onChange={e => setName(e.target.value)} />
                    </div>
                    <Form.Text className="text-muted">
                        You're picking: {name}
                    </Form.Text>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                    Submit
                    </Button>
                </Form> */}
            </div>
            {namesLoaded ? (<div className='name-entry'>
              {names.map((item, i) => (<Word key={item.name} text={item.name}></Word>))}
            </div>) : (<div className='name-entry'>Loading names...</div>)}
            {/* <div><Button variant='outline-primary' onClick={toTile}>Tile</Button></div>
            <div><Button variant='outline-primary' onClick={enableScoring}>Score</Button></div> */}
          </div>
        </div>
      </div>
  );
}

export default withAuthenticator(App);
